.gallery-category {
  margin-top: 50px;
  display: flex;
  gap: 15px;

  img {
    max-width: 300px;
    min-height: 168px;
    border-radius: 5px;
    max-height: 264px;
  }

  .title {
    color: #0e6c5c;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 30px;
    font-size: 25px;
  }
}

.gallery {
  text-align: center;
  margin-top: 50px;

  >.title {
    color: #0e6c5c;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 35px;
  }
}

.gallery-items {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;

  .item {
    cursor: pointer;
    background: #c1c1c1;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border: solid 1px #ccc;
    border-radius: 5px;
    padding: 15px;
    max-width: 200px;
    min-height: 226px;
    display: flex;
    flex-flow: column;
    align-items: start;

    .img {
      margin: auto;
    }

    img {
      width: 100%;
    }

    .description {
      margin: auto auto 5px;
      text-align: left;
    }

    .title {
      font-weight: bold;
      margin-bottom: 20px;
      font-size: 26px;
      text-align: center;
      width: 100%;
    }

    .foot-area {
      display: flex;
      flex-flow: column;
      font-weight: 500;
      text-align: left;
      margin-top: auto;
    }
  }
}

@media only screen and (max-width: 495px) {
  .gallery-category {
    display: block;
  }

  .gallery-category .title {
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .gallery-category {


    img {
      max-height: inherit;
    }
  }
}