#header {
  border-top: 2px solid #106d5d;

  .container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .row {
    margin-right: -15px;
    margin-left: -15px;
    display: flex;
    align-items: center;
  }

  .logo img {
    height: 110px;
  }
}

.nh-row {
  width: 100%;
  float: left;
  position: relative;
  display: block;
}

.nh-row {
  width: 100%;
  float: left;
  position: relative;
  display: block;
}

.navbar-right {
  display: flex;
  align-items: center;

  .form-suggestion {
    order: 0;
  }

  .donv-login {
    order: 1;
  }

  .search-suggestion {
    width: 200px;
    padding-right: 20px;
  }

  .form-group {
    position: relative;
    padding: 0;
    margin: 0;
    min-height: unset;

    .btn-submit {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 9;
    }
  }
}

@media only screen and (max-width: 495px) {
  #header {
    .logo img {
      height: auto;
    }
  }
}