.category-page {
  .head {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    background-size: cover;
    background-image: url("/assets/images/bg-category.jpg");

    @media screen and (max-width: 767px) {
      min-height: 120px;
    }

    >div {
      min-height: 300px;
      width: 100vw;
      background: rgba(0, 0, 0, 0.35);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;

      @media screen and (max-width: 767px) {
        min-height: 120px;
      }
    }

    .title {
      text-align: center;
      font-weight: 600;
      margin-bottom: 10px;
      font-size: 30px;
    }

    .breadcrump {
      text-align: center;
      font-size: 16px;

      a {
        color: #FFF;
      }
    }
  }

  .main {
    display: flex;
    padding: 30px;
    background-color: #FFF;

    .items {
      width: 100%;
      display: flex;
      flex-flow: wrap;
    }

    .item {
      max-width: 400px;
      margin-bottom: 30px;

      .img {
        border-radius: 15px;
        padding: 40px 20px;
        border: 1px solid #DDD;
      }

      .foot-area {
        margin-top: 10px;
        text-align: center;

        .price {
          color: #000;
          font-weight: bold;
        }
      }
    }

    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  .filters {
    min-width: 180px;

    >ul {
      margin-bottom: 50px;

      >li {
        font-weight: bold;
      }

      ul {
        li {
          margin-top: 5px;
        }
      }
    }

    text-align: left;

    input {
      margin-right: 10px;
    }

    label {
      user-select: none;
    }

    margin-right: 30px;
  }

  .MuiSlider-root,
  .MuiSlider-thumb {
    color: #0e6c5c;
  }
}