.up-down {
  position: absolute;
  top: 45%;
  right: 10px;
  z-index: 1;

  span {
    background: #88888834;
    color: #106d5d;
    user-select: none;
    cursor: pointer;
    border-radius: 100%;
    border: solid 1px #ccc;
    width: 30px;
    height: 30px;
    text-align: center;
    display: block;
    margin-bottom: 20px;

    &.down {
      padding-top: 5px;
    }
  }
}

.glassez-detail-page {
  margin-top: 50px;

  .ref-items {


    >.title {
      text-align: center;
      margin: 30px auto;
      margin-top: 50px;
      font-size: 20px;
      text-transform: uppercase;
      font-weight: bold;

    }

    .items {

      display: grid;
      gap: 5px;
      flex-flow: wrap;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

      .item {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        border-color: #ebebeb;
        background: #f8fafa;
        padding: 10px 5px;
        justify-content: center;
        min-height: 200px;
        border-radius: 5px;
        border: solid 1px #ccc;

        .price {
          color: #000;
          font-weight: bold;
          text-align: center;
          margin-top: 10px
        }
      }
    }
  }


  .MuiTabs-root {
    min-height: auto;
  }

  div[role="tabpanel"] {
    border: solid 1px #ccc;
    margin-top: -1px;
  }

  h2 {
    margin: 0;
    margin-bottom: 15px;
  }

  .glassez-container {
    display: flex;

    >div {
      width: 50%;
    }

    .glassez-images {
      .head {
        display: flex;
        margin-bottom: 20px;

        .best-seller {
          padding: 4px 8px;
          border-radius: 4px;
          background-image: -o-radial-gradient(-11% 19%,
              circle,
              #a7f4e4,
              #f2f6e8 46%,
              #a7f4e4 97%);
          background-image: radial-gradient(circle at -11% 19%,
              #a7f4e4,
              #f2f6e8 46%,
              #a7f4e4 97%);
          color: #222;
          text-transform: uppercase;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.5px;
          font-weight: 700;
          cursor: default;
        }
      }

      img {
        width: 100%;
        height: auto;
        cursor: pointer;
      }

      .image-item {
        background-color: #f1f2f4;
        padding: 20px;
      }
    }

    .sub-imgs {
      margin-top: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      align-items: center;

      .image-item {
        display: flex;
        align-items: center;
        height: 140px;
      }
    }

    .glassez-info {
      display: flex;
      flex-flow: column;
      margin-left: 20px;

      @media only screen and (max-width: 495px) {
        margin-left: 0;
        margin-top: 15px;
      }

      .head-border {
        display: flex;
        flex-flow: column;
        align-items: start;
      }

      .price {
        margin-bottom: 20px;
        font-size: 20px;
      }

      .description {
        text-align: left;
        margin: 20px;
        border-bottom: solid 1px #f8fafa;
        padding-bottom: 35px;
      }

      .foot-area {
        margin-bottom: 20px;

        .btn-primary {
          display: inline-block;
          border-radius: 20px;
          background-color: #106d5d;
          color: #ffffff;
          font-size: 14px;
          text-transform: uppercase;
          border: none;
          width: 150px;
          height: 40px;
          line-height: 1.75;
          padding: 8px 12px;
          margin-right: 12px;
        }
      }
    }
  }

  .MuiButton-root svg {
    padding-right: 5px;
  }
}

@media only screen and (min-width: 1200px) {

  .glassez-detail-page {
    .container {
      padding: 0 100px !important;
    }
  }
}

@media only screen and (max-width: 495px) {
  .glassez-detail-page {
    margin-top: 25px;

    .glassez-container {
      flex-flow: column;

      >div {
        width: 100%;
      }

      .mobile .code {
        font-weight: bold;
      }
    }

    .detail img {
      width: 100% !important;
      height: auto !important;
    }
  }
}

.desc-tabs {
  .MuiTabs-flexContainer {
    display: flex;


  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiButtonBase-root {
    color: #222;
    font-size: 14px;
    text-transform: unset;
    min-height: unset;
    border: 1px solid transparent;

    border-color: #ccc;

    &.Mui-selected {
      background-color: #c43b68;
      border-color: #ccc;
      color: #fff;
      border: none;

    }
  }

  .MuiButtonBase-root:last-child {
    border-left: none;
  }
}

.carousel-root {
  display: flex;



  .carousel-slider {
    background: #f8fafa;
    border: solid 2px #ebebeb;
    min-height: 238px;
    border-radius: 3px
  }

  >.carousel {
    order: 1;
    flex: 1;

    .slider {
      height: 100%;

      .slide {
        >div {
          display: flex;
          justify-content: center;
        }
      }

    }

    .slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .thumbs-wrapper {
      margin: 0;

      .thumbs li {
        height: 80px;
        border-radius: 5px;
        border-color: #ebebeb;
        border-width: 2px;
        display: flex;
        align-items: center;

        &.selected {
          border-color: #c3f1ff;
        }

        background: #f8fafa;


      }
    }
  }

  >.carousel-slider {
    display: flex;
    order: 1;
    flex: 10;
    padding: 0 5px
  }

  .thumbs-wrapper {
    width: 100px;

    .thumbs {
      align-items: end;
      display: flex;
      flex-direction: column;
      transform: none !important;

      .thumb {
        margin-bottom: 16px;
      }
    }
  }

}