.hide {
  display: none;
}
#camera-dialog {
  z-index: var(--yarl__portal_zindex, 99999);
  div[role="dialog"] {
    max-width: 650px;
    box-shadow: none;
    background: transparent;
    margin: 0 15px;
    .MuiDialogContent-root {
      padding: 0;
      overflow: hidden;
    }
  }
}
.appbar {
  text-align: left;
  background-color: rgba(251, 251, 253, 0.8) !important;
  z-index: 100;
  .MuiToolbar-root {
    color: #000;
  }
  a {
    color: #000;
    text-decoration: none;
    display: flex;
    img {
      max-height: 25px;
      margin-right: 10px;
    }
  }
}
.yarl__thumbnails_thumbnail {
  background-color: #5c5c5c !important;
}
.App {
  // text-align: center;
  // max-width: 1200px;
  margin: 0 auto;
  // padding-bottom: 60px;
  // padding-left: 10px;
  // padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow: hidden;
  position: relative;
}

#draw-wrap {
  position: relative;

  .loading {
    position: absolute;
    z-index: 9999;
    left: calc(50% - 100px);
    top: calc(50% - 100px);
  }
  video {
    padding: 0;
    margin: 0;
  }
}
.camera-feed,
#output_canvas {
  margin: 16px 0;
  border-radius: 16px;
  width: 100%;
  // height: 300px;
}

#output_canvas {
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
#glass-image {
  display: none;
}

// Disable because overlap with carosuel
// .animated {
//   display: flex;
//   align-items: center;
//   width: 50px;
//   height: 50px;
//   background-color: blue;
//   color: #fff;
//   position: absolute;
//   border-radius: 100%;
//   transition: transform 1s ease-in-out;
//   right: 200px;
//   margin-top: -100px;
//   svg {
//     margin-left: 10px;
//   }
// }

.move {
  transform: translate(0, 0);
}

.cart-dialog {
  z-index: 1;
  position: fixed;
  top: 65px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cart-dialog-content {
  padding: 10px;
  h2 {
    margin: 5px 0;
    border-bottom: solid 1px #ccc;
    padding-bottom: 10px;
  }
}
.pay-wrap {
  display: flex;
  align-items: center;
  button {
    margin: 0 auto;
  }
}
.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.list-cart-item {
  background-color: #fff;
  list-style: none;
  padding: 0;
  li {
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    padding-bottom: 10px;
    border-bottom: dashed 1px #ccc;
    align-items: center;
    .cart-item-img {
      max-height: 50px;
      max-width: 100px;
      width: 100%;
    }
    .product-title {
      font-weight: 500;
      width: 150px;
      text-align: left;
      padding-left: 20px;
    }
    input {
      width: 55px;
      text-align: center;
      padding: 5px;
    }
    .remove-button {
      padding: 5px;
    }
  }
}

.total-price-title {
  font-weight: bold;
}
.total-price {
  color: red;
  font-weight: 500;
}
//contact form
.contact-form {
  .form-row label {
    display: flex;
    align-items: center;
    .title {
      width: 200px;
      text-align: right;
      padding: 15px 20px;
    }
    input {
      width: 200px;
      padding: 8px;
    }
  }
  button {
    margin-top: 20px;
  }
  .back-icon {
    position: absolute;
    left: 20px;
    top: 22px;
    color: #616161;
  }
}
.MuiAlert-message {
  text-align: left;
}

.MuiSnackbar-root {
  margin-top: 46px;
}

@media only screen and (max-width: 495px) {
  .gallery {
    margin-top: 25px;
    .gallery-items .item {
      max-width: inherit;
    }
  }
  .list-cart-item li .mobile {
    display: flex;
    flex-flow: column;
    align-items: center;
    .product-title {
      padding-left: 0;
      text-align: center;
    }
  }
}

.breadcrump {
  font-size: 12px;
}
